import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { BiX } from 'react-icons/bi';
import { Locale } from '../../../i18n-config';

export default function OfferBanner({ setShow, Links, lang }) {
  const { t } = useTranslation(lang as Locale);

  return (
    <div className="offer-alert tw-relative tw-max-h-[30px] tw-flex tw-items-center tw-w-full tw-justify-center tw-bg-[#2562eb]">
      <Link
        passHref
        href={Links.register.href}
        className="tw-py-4 tw-text-center tw-flex tw-items-center tw-gap-2 tw-text-[#EEF1FD] tw-text-sm hover:tw-text-white"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.26044 23.0854L21.6397 2.39572L19.7387 0.798828L17.0989 3.94141H4C2.897 3.94141 2 4.83841 2 5.94141V17.9414C2 18.9356 2.7288 19.7625 3.68035 19.9159L2.35938 21.4885L4.26044 23.0854ZM5.33892 17.9414H4V11.9414H10.3789L5.33892 17.9414ZM13.7389 7.94141L15.4189 5.94141H4V7.94141H13.7389ZM20 7.94141H18.569L15.2646 11.9414H20.001L20.002 17.9414H10.3081L8.65592 19.9414H20C21.103 19.9414 22 19.0444 22 17.9414V5.94141C22 5.38304 21.7701 4.87746 21.4 4.51432L20 6.2091V7.94141Z"
            fill="#ffffff"
          />
        </svg>
        {t('Layout/Banner/key1')}
      </Link>
      <BiX
        onClick={() => setShow(false)}
        size={20}
        className="tw-absolute tw-right-2 tw-cursor-pointer"
        color="#697177"
      />
    </div>
  );
}
